<template>
  <div>
    <b-alert
      variant="danger"
      :show="isSubscriptionExpired"
    >
      <div class="alert-body">
        انتهت صلاحية اشتراك شركتك ، يرجى تجديده. 
        <b-link
        class="alert-link border-link"
        :to="{ name: 'invoices-list'}"
        >
          قائمة الإشتراك
        </b-link>
      </div>
    </b-alert>

    <div class="row">
      <div class="col-sm-6 col-lg-3">
        <statistic-card-horizontal
          color="info"
          icon="UserIcon"
          :statistic="clientsCount"
          statistic-title="عدد العملاء"
          style="max-width:300px"
        />
      </div>
      <div class="col-sm-6 col-lg-3">
        <statistic-card-horizontal
          color="success"
          icon="FileIcon"
          :statistic="contractsCount"
          statistic-title="عدد العقود"
          style="max-width:300px"
        />
      </div>
      <div class="col-sm-6 col-lg-3">
        <statistic-card-horizontal
          color="primary"
          icon="FileIcon"
          :statistic="quotationsCount"
          statistic-title="عدد  العروض"
          style="max-width:300px"
        />
      </div>
      <div class="col-sm-6 col-lg-3">
        <statistic-card-horizontal
          color="danger"
          icon="FileIcon"
          :statistic="evaluationRequestsCount"
          statistic-title="عدد طلبات التقييم"
          style="max-width:300px"
        />
      </div>
    </div>

    <b-row class="match-height">
      <b-col lg="12">
        <card-analytic-goal-overview :card-data="requestsStatusData" />
      </b-col>
    </b-row>

    <!-- <b-card title=" إبدء مشروعك 🚀">
      <b-card-text> كل ماتحتاجه لمشروعك الجديد.</b-card-text>
      <b-card-text>يرجى التأكد من مراجعة دليل الاستخدام
        <b-link href="#" target="_blank"> دليل الاستخدام </b-link> لفهم كيفية
        استخدام النظام .</b-card-text>
    </b-card>

    <b-card title="تريد تقييم؟ 🔒">
      <b-card-text>قمنا بتطوير نظام مقييم بعناية بحيث يمكنك تقييم العقارات الخاصة بك
        بسهولة ومع ضمان مردودية عالية.</b-card-text>
      <b-card-text>يرجى قراءة وثائقنا لمعرفة المزيد عن نظامنا.</b-card-text>
    </b-card> -->
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api';
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue';
import CardAnalyticGoalOverview from '@core/components/cards/CardAnalyticGoalOverview.vue';
import {
  BCard, BCardText, BLink, BAlert, BRow, BCol,
} from 'bootstrap-vue';
import store from '@/store';
import userStoreModule from './modules/user/userStoreModule';

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BAlert,
    BRow,
    BCol,
    StatisticCardHorizontal,
    CardAnalyticGoalOverview,
  },
  data() {
    const userData = JSON.parse(localStorage.getItem('userData'));
    return {
      userData,
      isSubscriptionExpired: userData.is_subscription_expired ?? false,
    };
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user';

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const clientsCount = ref(0);
    const contractsCount = ref(0);
    const quotationsCount = ref(0);
    const evaluationRequestsCount = ref(0);
    const requestsStatusData = ref(null);

    store.dispatch('app-user/fetchStats').then((res) => {
      clientsCount.value = res.data.clients_count;
      contractsCount.value = res.data.contracts_count;
      quotationsCount.value = res.data.quotations_count;
      evaluationRequestsCount.value = res.data.current_month_evaluation_requests_count;

      const labels = res.data.requestsByStatus.map((item) => item.label);
      const chartOptions = {
        title: 'طلبات التقييم حسب الحالة',
        chart: 'donut',
        series: res.data.requestsByStatus.map((item) => item.count),
        chartOptions: {
          labels,
        },
        data: res.data.requestsByStatus.map((item) => ({
          label: item.label,
          value: item.count,
        })),
      };

      // totalClients.value = res.data.totalClients;
      requestsStatusData.value = chartOptions;
    });

    return {
      clientsCount,
      contractsCount,
      quotationsCount,
      evaluationRequestsCount,
      requestsStatusData,
    };
  },

};
</script>

<style>
.border-link {
  border: 1px solid rgb(254, 176, 25) ; /* Adjust the color and width as needed */
  padding: 2px 4px; /* Optional padding to make it look better */
  border-radius: 4px; /* Optional rounded corners */
  text-decoration: none; /* Remove underline if necessary */
}

.border-link:hover {
  background-color: rgba(255, 0, 0, 0.1); /* Optional hover effect */
}

</style>
